import { pickerCurrentTime } from "@9amhealth/shared";
import {
  DateFormatter,
  getLocalTimeZone,
  parseDate,
  toZoned,
  ZonedDateTime
} from "@internationalized/date";
import { getSupportedUserLocale } from "src/lib/i18next";
import translate from "src/lib/translate";
import { z } from "zod";

export const validateDate = z.custom<ZonedDateTime>().refine(
  (v) => {
    if (!(v instanceof ZonedDateTime)) return false;
    const asZoned = v;

    const now = pickerCurrentTime();

    if (asZoned.year < now.year - 3) return false;

    return asZoned.compare(now) <= 0;
  },
  { message: translate("error_validate_date_min_three_years") }
);

export const getToZonedDate = (minDate: string) => {
  const minDateTrimmed = minDate.split("T")[0];
  const minDateZoned = toZoned(parseDate(minDateTrimmed), getLocalTimeZone());

  return minDateZoned;
};

export const validateMinDateTime = (minDateTime: ZonedDateTime) =>
  z.custom<ZonedDateTime>().superRefine((v, ctx) => {
    if (!(v instanceof ZonedDateTime)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        message: translate("error.date")
      });
      return z.NEVER;
    }
    const asZoned = v;

    const now = pickerCurrentTime();

    if (asZoned.compare(minDateTime) < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translate("error_validate_min_date", {
          minDate: minDateTime.toDate().toLocaleString()
        })
      });
      return z.NEVER;
    }

    if (asZoned.compare(now) >= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translate("error_validate_date_current")
      });
      return z.NEVER;
    }
  });

export const validateDateGLP1 = (minDate?: string) =>
  z.custom<ZonedDateTime>().superRefine((v, ctx) => {
    if (!(v instanceof ZonedDateTime)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translate("error.date"),
        path: ["date"],
        fatal: true
      });

      return z.NEVER;
    }

    if (minDate) {
      const minDateZoned = getToZonedDate(minDate);

      if (v.compare(minDateZoned) < 0) {
        const formatter = new DateFormatter(getSupportedUserLocale());
        const minDateFormatted = formatter.format(minDateZoned.toDate());

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translate("error_validate_min_date_glp1", {
            minDate: minDateFormatted
          }),
          path: ["date"]
        });
      }
    }

    const now = pickerCurrentTime();

    if (v.compare(now.add({ months: 3 })) > 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translate("error_validate_date_current"),
        path: ["date"]
      });
    }

    return z.NEVER;
  });
